<template>
  <div class="s3m-image_input">
      <v-file-input
          v-model="image"
          :label="label"
          accept="image/png, image/jpeg, image/webp"
          append-icon="mdi-close"
          @click:append="clearImage()"
      > </v-file-input>
      <img v-if="file && Object.keys(file).length > 0" :src="imageUrl">
      <v-alert v-if="message" border="left" color="red" dark v-html="message"/>
    </div>
</template>

<script>

import APIService from "@/services/APIService";

export default {
  name: "ImageInput",
  props: {
    value: {
      type: Object,
    },
    label: {
      type: String,
      required: false,
    },
    sizeLimit: {
      type: Number,
      required: false, // Größenlimit ist jetzt optional
      default: null,   // Standardwert ist null
    }
  },
  data() {
    return {
      image: undefined,
      file: undefined,
      message: ''
    }
  },
  methods: {
    clearImage() {
      this.file = undefined
      this.$emit('input', undefined)
    }
  },
  computed: {
    imageUrl() {
      return `${process.env.VUE_APP_ROOT_API}/content/${this.file.path}`
    }
  },
  watch: {
    image: function(newImage) {
      console.log("upload file", this.image)

      if(this.sizeLimit && newImage.size > this.sizeLimit * 1000) { // Überprüfen Sie das Größenlimit, wenn es gesetzt ist.
        this.message = `Dateigröße sollte weniger als ${this.sizeLimit}KB sein`;
        this.file = undefined;
      } else if(newImage) {
        APIService.upload(newImage).then((response) => response.data).then(fileMeta => {
          this.file = { id: fileMeta.id, path: fileMeta.path }
          this.$emit('input', this.file)
          this.image = undefined
        }).catch((error) => {
          this.message = `Das Bild konnte nicht übertragen werden:<br> ${error.response?.data?.message}`;
        })
      }

      /*
      if(this.image) {
        APIService.upload(this.image).then((response) => response.data).then(fileMeta => {
          this.file = { id: fileMeta.id, path: fileMeta.path }
          this.$emit('input', this.file)
          this.image = undefined
        }).catch((error) => {
          this.message = `Das Bild konnte nicht übertragen werden:<br> ${error.response?.data?.message}`;
        })
      }*/
    },
    value: function() {
      console.log("Value changed")
      this.message = ''
      this.image = undefined
      this.file = this.value
    },
  },
  mounted() {
    this.file = this.value
  }
}
</script>

<style lang="scss" scoped>
  img {
    max-width: 200px;
  }
</style>
