var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"s3m-internetuser_list"},[_c('h1',[_vm._v("User Logins")]),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"value":_vm.search,"append-icon":"mdi-magnify","label":"Suche","single-line":"","hide-details":""},on:{"input":_vm.searchHandler}})],1),_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"rounded":"","color":"primary","dark":""},on:{"click":_vm.createUser}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Kunde ")],1)],1)],1)],1),_c('v-data-table',{ref:"userTable",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"search":_vm.search,"items-per-page":_vm.itemsPerPage,"hide-default-footer":_vm.users.length < 5,"footer-props":{
          'items-per-page-text':'Zeilen',
          'items-per-page-options': [15, 50, 100, -1]
        }},on:{"update:options":_vm.updateOptions,"current-items":_vm.getFiltered},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.deleteUser(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.role",fn:function(ref){
        var item = ref.item;
return [_c('v-chip',{staticClass:"ma-0",staticStyle:{"color":"white","width":"80px"},attrs:{"color":item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue',"small":""}},[_vm._v(" "+_vm._s(item.role)+" ")])]}}])})],1),_c('user-edit-dialog',{ref:"userEditDialog"}),_c('confirm-dialog',{ref:"confirmDelete"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }