<template>
  <div :class="listClass">
    <h1>{{title}}</h1>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                append-icon="mdi-magnify"
                label="Suche (min. 3 Zeichen)"
                single-line
                hide-details
                :value="search"
                @input="searchHandler"
            ></v-text-field>
          </v-col>
          <v-spacer/>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="filteredUsers"
          :search="search"
          :items-per-page="50"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          :options.sync="paginationSync"
          :footer-props="{
            'items-per-page-text': 'Datensätze',
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.InternetUser="{item}">
          <div class="s3m_pointer" @click="openLoginUser(item)">{{ item.InternetUser }}</div>
        </template>
        <template v-slot:item.completeName="{ item }">
          <span class="s3m_pointer" @click="openKunde(item, 'P')">{{ (item.completeName) ? item.completeName : "" }}</span>
        </template>
        <template v-slot:item.F_KundeObj.KurzbezeichnungExtern="{ item }">
          <span class="s3m_pointer" @click="openKunde(item, 'F')">{{ (item.F_KundeObj && item.F_KundeObj.KurzbezeichnungExtern) ? item.F_KundeObj.KurzbezeichnungExtern : "" }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <user-edit-dialog ref="userEditDialog"/>
    <generated-form ref="generatedForm"/>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>

</template>

<script>
//import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import GeneratedForm from "@/components/GeneratedForm";
import APIService from "@/services/APIService";
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import Fuse from "fuse.js";
import userService from "@/services/user.service";
export default {
  name: "APList",
  mixins: [vuetifyTableMixin],
  components: {
    GeneratedForm,
    UserEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      listClass: 's3m-user_list',
      showLoader: false,
      paginationSync: {rowsPerPage: 100},
      filters: [],
      title: 'Ansprechpartner',
      users: [],
      filteredUsers: [],
      aps: [],
      companies: [],
      timeout: null
    }
  },
  methods: {
    searchHandler (text) {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // Stellen Sie sicher, dass die keys den Namen Ihrer Felder in den Benutzerdaten entsprechen
        let options = {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          includeScore: true,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          useExtendedSearch: true, // Enable extended search
          tokenize: true,
          matchAllTokens: true,
          keys: ["suchstring"]
          //keys: ["Vorname", "Nachname1", "Nachname2", "PLZ_Str", "Strasse", "InternetUser", "Ort"]
          //keys: ["Vorname", "Nachname1", "Ort"]
        };

        text = text.trimEnd();

        if(text.length > 2) {
          let fuse = new Fuse(this.aps, options);
          let extendedSearchQuery = text.split(' ').map(word => `'${word}`).join(' '); // Format the search query as extended search
          let results = fuse.search(extendedSearchQuery);
          this.filteredUsers = results.map(result => result.item);
        }else if(text.length === 0){
          this.filteredUsers = this.aps;
        }
      }, 500); // delay
    },
    async openLoginUser(item) {
      let user = await APIService.getUserByEmail(item.InternetUser);

      if (user === undefined) {
        this.$refs.confirmDelete.show({
          title: 'Login-User erstellen',
          text: `Der Login-User mit der E-Mail Adresse ${item.InternetUser} ist nicht vorhanden. Soll er erstellt werden?`,
          confirm: 'Ja',
        }).then(async () => {
          userService.createLoginUser(item, item.id)
              .then(async () => {
                user = await APIService.getUserByEmail(item.InternetUser);
                this.$refs.userEditDialog.show(user)
              })
              .catch(error => console.log(error));
        });
      } else {
        console.log("open user edit dialog");
        console.log(user);
        this.$refs.userEditDialog.show(user);
      }
    },
    openKunde(item,kundeTyp){
      let kunde = item.F_KundeObj;
      if(kundeTyp === "P") {
        kunde = item.P_KundeObj;
      }
      this.$refs.generatedForm.show("kunde", kunde, kunde.id, kundeTyp)
    },
    async entered(){
        this.fetchUsers();
    },
    async fetchUsers(){
      console.log("fetch users");
      this.showLoader = true;

      let promises = [
        APIService.getAllKundeAp(),
        APIService.getAllModel("kunde", [{"name": "KundeTyp", "value": "P"}]),
        APIService.getAllModel("kunde", [{"name": "KundeTyp", "value": "F"}])
      ];

      try {
        let [aps, users, companies] = await Promise.all(promises);
        this.aps = aps;
        this.users = users;
        this.companies = companies;
      } catch (error) {
        console.log("Ein Fehler ist aufgetreten: ", error);
      }

      let usersDict = {};
      this.users.forEach(user => usersDict[user.id] = user);
      let companiesDict = {};
      this.companies.forEach(company => companiesDict[company.id] = company);

      // Now use these dictionaries in your map function
      this.aps = this.aps.map(ap => {
        // Access the corresponding user and company directly from the dictionaries
        let P_KundeObj = usersDict[ap.P_Kunde];
        let F_KundeObj = companiesDict[ap.F_Kunde];

        // Add them to the ap object
        return { ...ap, P_KundeObj, F_KundeObj };
      });

      for(let i=0; i < this.aps.length; i++){
        if(this.aps[i].Archiv === "-1"){
          this.aps.splice(i, 1);
          continue;
        }
        let P_KundeObj = this.aps[i].P_KundeObj;
        if (P_KundeObj) {
          let Vorname = P_KundeObj.Vorname || "";
          let Nachname1 = P_KundeObj.Nachname1 || "";
          this.aps[i].completeName = Vorname + " " + Nachname1;
        } else {
          this.aps[i].completeName = "";
        }
        let F_KundeObj = this.aps[i].F_KundeObj;
        let KurzbezeichnungExtern = F_KundeObj ? F_KundeObj.KurzbezeichnungExtern || "" : "";
        let InternetUser = this.aps[i] && this.aps[i].InternetUser || "";

        this.aps[i].suchstring = KurzbezeichnungExtern + " " + this.aps[i].completeName + " " + InternetUser;
      }
      console.log(this.aps);
      this.filteredUsers = this.aps;
      this.showLoader = false;
    },
    editUser(item){
      console.log("open model");
      console.log(item);
      this.$store.state.copyUser = null;
      this.$refs.generatedForm.show("kundeap", item)
    }
  },
  computed: {
    headers(){
      const headers = [
        { text: 'Name', value: 'completeName' },
        { text: 'LoginUser', value: 'InternetUser' },
        { text: 'Firma', value: 'F_KundeObj.KurzbezeichnungExtern' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
        {
          value: 'suchstring',
          align: ' d-none'
        }
      ]
      if(this.kundetyp === "P"){
        headers.splice(4, 1);
      }else{
        //headers.splice(0,1);
      }
      return headers;
    }
  },
  mounted() {
    this.$root.$off("updatekundeList");
    this.$root.$on('updatekundeList', (msg) => {
      console.log("update list "+msg+"1");
      if(msg === "kunde"){
        this.fetchUsers();
      }
    });
  },
  watch:{
    $route (){
      this.entered();
    }
  },
  created() {
    this.entered();
  }
}
</script>

<style scoped>

</style>
