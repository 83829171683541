<template>
  <div>
    <tiptap-vuetify
        class="editor"
        v-model="localValue"
        :extensions="extensions"
    />
  </div>
</template>
<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'

export default {
  name: "TextEditor",
  components: {TiptapVuetify},
  props: {
    value: {
      required: true,
      validator(value) {
        return typeof value === 'string' || value === undefined;
      },
    },
  },
  data()  {
    return {
      localValue: this.value,
      content: "",
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3]
          }
        }],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak
      ],
    };
  },
  watch: {
    localValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.localValue = newValue;
    }
  }
}
</script>

<style scoped lang="scss">
</style>
