<template>
  <div class="s3m-group_select">
    <v-row justify="center">
      <OverlayDialog
          v-model="showDialog"
          persistent
          max-width="800px"
          style="z-index:100"
          v-on:close="this.close"
          :show-save-button="false"
          content-class="s3m-gruppe_select_dialog"
          class="s3m-gruppe_select_dialog"
      >
      <h1>{{title}}</h1>

      <v-card>
        <v-card-title>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Suche"
                  single-line
                  hide-details
              ></v-text-field>
            </v-col>
            <v-spacer/>
            <v-btn @click="syncGroups" color="red" class="s3m_manual_sync">Manuell synchronisieren</v-btn>
          </v-row>
        </v-card-title>
        <v-data-table
            :headers="headers"
            :items="list"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :search="search"
            :items-per-page="itemsPerPage"
            @update:options="updateOptions"
            class="elevation-1"
            :hide-default-footer="list.length < 5"
        >
          <template v-slot:item.actions="{ item }">
            <v-icon
                v-if="!item.userHasGroup"
                small
                @click="addToGroup(item)"
            >
              mdi-plus
            </v-icon>
            <v-icon
                v-if="item.userHasGroup"
                small
                @click="removeFromGroup(item)"
            >
              mdi-minus
            </v-icon>
          </template>
        </v-data-table>
      </v-card>
        <confirm-dialog ref="confirmDelete"/>
        <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
      </OverlayDialog>
    </v-row>
  </div>
</template>

<script>
//import APIService from "@/services/APIService";
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import OverlayDialog from "@/components/OverlayDialog.vue";
import modelService from "@/services/model.service";
import sammelgruppeService from "@/services/sammelgruppe.service";
import APIServiceSync from "@/services/APIServiceSync";

export default {
  name: "SGGruppeSelectDialog",
  mixins: [vuetifyTableMixin],
  components: {
    OverlayDialog,
    ConfirmDialog
  },
  data() {
    return {
      showDialog: false,
      showLoader: false,
      type: "",
      item: {},
      search: '',
      title: 'Gruppen in Sammelgruppe',
      list: [],
      userGruppen: [],
      sortBy: 'userHasGroupTxt',
      sortDesc: true,
      headers: [
        { text: 'Bezeichnung', value: 'Bezeichnung' },
        { text: 'Kurzbezeichnung', value: 'Kurzbezeichnung' },
        { text: 'Mitglied', value: 'userHasGroupTxt'},
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
      ]
    }
  },
  methods: {
    show(item){
      console.log(item);
      this.model = item;
      this.showDialog = true;
      this.fetchModel();
      this.userGruppen = item.gruppen;
      this.title = "Gruppen in "+item.name;
    },
    close(){
      this.showDialog = false;
    },
    async addToGroup(item){
      this.showLoader = true;
      console.log("add to "+this.model.id);
      await sammelgruppeService.addGroup(this.model, item);
      //await modelService.saveModel(this.model, "sammelgruppe", this.model.id)
      await this.fetchModel();
      this.showLoader = false;
      this.$root.$emit('updatesammelgruppeList', "sammelgruppe");
    },
    async removeFromGroup(item){
      this.showLoader = true;
      await sammelgruppeService.removeGroup(this.model, item);
      await this.fetchModel();
      this.showLoader = false;
      this.$root.$emit('updatesammelgruppeList', "sammelgruppe");
    },
    async syncGroups(){
      await this.$refs.confirmDelete.show({
        title: 'Achtung!',
        text: `Die Synchronisation kann einige Minuten dauern!`,
        confirm: 'Ok, starten'
      })
      this.showLoader = true;
      await sammelgruppeService.saveModel(this.model, this.model.id);
      await APIServiceSync.syncBG2LD(this.model.id);
      this.showLoader = false;
    },
    async fetchModel(){

      this.showLoader = true;
      this.userGruppen = this.model.groups;
      this.list = await modelService.getModel("gruppe");

      for(let i=0; i < this.list.length; i++){
        for(let j = 0; j < this.model.groups.length; j++){
          if(this.model.groups[j] === this.list[i].id){
            this.list[i].userHasGroup = true;
            this.list[i].userHasGroupTxt = "Ja";
            //this.list[i].gruppemitglied = this.userGruppen[j].gruppemitglied;
          }
        }
      }
      this.showLoader = false;
      //this.$refs.dataTable.refresh();
    }
  },
  /*
  computed: {
    ...mapState(['users'])
  },*/
  watch:{
    $route (){
      //this.entered();
    }
  },
  created() {
    //this.entered();
  }
}
</script>

<style scoped>

</style>
