<template>
  <div class="s3m-settings_list">
    <h1>Mission Stuckateur – Betriebesuche</h1>
    <v-card class="s3m-card-content">
      <div>
        Hier können Sie angeben, mit welchen Ausbildungsplatzangeboten Ihr Unternehmen auf <a href="https://www.mission-stuckateur.de">www.mission-stuckateur.de</a> gelistet sein soll. Darüber werden Sie im sog. Ausbildungsnavigator von Interessenten gefunden. Die übrigen Firmendaten, wie auch Ihr Logo, können Sie im Menüpunkt Kundendaten ansehen und ändern.
      </div>
      <div class="">
        <h3>Was bilde ich aus:</h3>
        <div v-for="option in this.azGroups" :key="option.id">
          <label v-if="!option.restricted || editUser.kundeObj.gruppen.some(group => group.id === '65c31d33e544150026f4aa9e')">
            <!--
            <input @change="updateGroupMemberStatus(option)" type="checkbox" v-model="option.isMember">
            {{ option.Bezeichnung }}
            -->
            <input type="checkbox" v-model="option.tempIsMember">{{ option.Bezeichnung }}
          </label>
        </div>
      </div>
      <v-btn class="save-button" color="primary" @click="saveChanges">Speichern</v-btn>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import gruppeService from "@/services/gruppe.service";
import modelService from "@/services/model.service";
import gruppemitgliedService from "@/services/gruppemitglied.service";
import Snackbar from "@/components/Snackbar.vue";

export default {
  name: "Azubi",
  components: {Snackbar, ConfirmDialog },
  data() {
    return {
      teamMembers: [],
      managers: {},
      file: null,
      imageUrl: null,
      azGroups: [],
      showLoader: false,
      isSAFMember: false,
      editUser: null,
      snackbarSuccess: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarError: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-close',
        color: 'error'
      },
    }
  },
  methods: {
    ...mapMutations(['updateManagers']),
    async fetchData() {
      console.log(this.editUser);
      // 65c31d34e544150026f4ad4b
      this.isSAFMember = this.editUser.kundeObj.gruppen.some(gruppe => gruppe.id === '669ca043422de3002820585e');

      console.log("is SAF member? "+this.isSAFMember);

      //this.azGroups = await sammelgruppeService.getModel('65d46cb8dcd86000277c9944');
      let [sg, userGroups, selfservGroups] = await Promise.all([
        APIService.getAllSammelGruppen([{name: 'name', value: 'Mission Stuckateur Gruppen'}]),
        gruppemitgliedService.getGruppenForKunde(this.editUser.kundeObj.id, this.editUser.kundeObj.KundeTyp),
        //APIService.getMyGroups(),
        APIService.getAllSelfServiceGroups()
      ]);

      console.log(sg);
      console.log(userGroups);
      console.log(selfservGroups);

      if(this.user.role !== "administrator" && (!sg || sg.length === 0 || !sg[0].groups || sg[0].groups.length === 0)){
        sg.push({groups: selfservGroups});
      }
      console.log(sg);

      if(sg.length > 0) {
        for (let i = 0; i < sg[0].groups.length; i++) {
          let gtmp;

          if (typeof sg[0].groups[i] === 'object') {
            gtmp = sg[0].groups[i];
          } else if (typeof sg[0].groups[i] === 'string') {
            gtmp = await gruppeService.getModel(sg[0].groups[i]);
          }else{
            return;
          }

          //Filter Maler 65c31d34e544150026f4afaf
          //Filter Trockenbaumonteur 65c31d34e544150026f4afad

          if(!this.isSAFMember && (gtmp.id === "669ca043422de30028205ac0" || gtmp.id === "669ca043422de30028205ac2")){
            continue;
          }
          if (gtmp) {
            gtmp.isMember = userGroups.some(group => {
              if(group.id === gtmp.id) {
                // Zur Zuweisung von gruppemitgliedId, wenn group.id mit gtmp.id übereinstimmt
                gtmp.gruppemitgliedId = group.Gruppemitglied.id;
                return true;
              }
              return false;
            });
            gtmp.tempIsMember = gtmp.isMember;
            this.azGroups.push(gtmp);
          }
        }
      }

    },
    async updateGroupMemberStatus(group) {
      // Hier können Sie Ihren API-Aufruf platzieren, um den Status zu speichern.
      // Dies ist nur ein Dummy-Beispiel. Sie sollten es durch Ihren tatsächlichen API-Aufruf ersetzen.
      try {
        console.log(group);
        if(group.isMember){
          await this.addToGroup(group);
        }else{
          await this.removeFromGroup(group);
        }
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Gruppenmitgliedsstatus: ", error);
      }
    },
    async saveChanges() {
      this.showLoader = true;
      // Iteriert über alle Gruppen und überprüft, ob sich der Zustand der CheckBox geändert hat
      // Wenn sich der Zustand geändert hat, dann wird die API-Aktion ausgeführt und der isMember Wert aktualisiert.
      for (const group of this.azGroups) {
        if (group.isMember !== group.tempIsMember) {
          if (group.tempIsMember) {
            await this.addToGroup(group);
          } else {
            await this.removeFromGroup(group);
          }
        }
      }
      console.log("saved!");
      this.showLoader = false;
      this.$refs.snackbar.show(this.snackbarSuccess)
    },
    async addToGroup(item){
      let model = this.createModel(item);
      model.InternetUser = this.editUser.kundeObj.InternetUser;
      console.log(model);
      await gruppemitgliedService.saveModel(model, false, false);
    },
    async removeFromGroup(item){
      console.log("remove from group");
      //this.showLoader = true;

      item.InternetUser = this.editUser.kundeObj.InternetUser;
      item.Gruppe = item.id;
      item.id = item.gruppemitgliedId;

      console.log(item);
      await gruppemitgliedService.deleteModel(item, false, false);
      await modelService.getContext(item);
      //this.showLoader = false;
    },
    createModel(gruppe){
      const current = new Date();
      let month = current.getMonth()+1;
      month = (month < 10) ? '0'+month : month;
      let day = current.getDate();
      day = day < 10 ? '0'+day : day;
      const date = `${current.getFullYear()}-${month}-${day}`;
      let model = {};
      if(this.editUser.kundeObj.KundeTyp === "F"){
        model = {
          Gruppe: gruppe.id,
          GruppeFunktion: "643562aa7304db0027195b0a",
          F_Kunde: this.editUser.kundeObj.id,
          Datum: date
        };
      }else{
        model = {
          Gruppe: gruppe.id,
          GruppeFunktion: "643562aa7304db0027195b0a",
          P_Kunde: this.editUser.kundeObj.id,
          Datum: date
        };
      }

      return model;
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {
    ...mapState(['user']),
  },
  async beforeRouteEnter(to, from, next) {
    next( async vm => {
      // Wir überprüfen, ob 'editUser' im Zustand gesetzt ist und benutzen diesen, ansonsten benutzen wir 'user'
      const targetUser = vm.$store.state.editUser ?? vm.$store.state.user

      // 'editUser' wird zu 'user' wenn 'editUser' gesetzt ist
      vm.editUser = targetUser

      vm.managers = [...targetUser.managers]
      vm.teamMembers = await APIService.getTeamMembers(targetUser.id)
    })
  }
}
</script>

<style scoped>

</style>
