<template>
  <div class="s3m-bsuche">
    <div class="content">
      <h1>Ratgeber Raum und Fassade – Expertensuche</h1>
      <v-card class="s3m-card-content">
        <div>
          Hier können Sie angeben, mit welchen Daten Ihr Unternehmen auf der Website <a href="https://www.ratgeber-raum-fassade.de">www.ratgeber-raum-fassade.de</a> bei der Expertensuche gelistet sein soll. Die übrigen Firmendaten, wie auch Ihr Logo, können Sie im Menüpunkt Kundendaten ansehen und ändern.
        </div>
        <div class="profile s3m-card">
          <h3>Profil</h3>
          <v-text-field  label="Facebook" v-model="company.contact.facebook"></v-text-field>
          <v-text-field  label="Instagram" v-model="company.contact.instagram"></v-text-field>
          <v-text-field  label="Click2Meet" v-model="company.contact.clickToMeet"></v-text-field>
          <div class="mb-1 mt-2">Beschreibung</div>
          <text-editor
              v-model="company.info.description"
          />
          <div class="mb-1 mt-6">Öffnungszeiten</div>
          <text-editor
              v-model="company.info.openingHours"
          />

          <image-input
              v-if="false"
              v-model="company.logo"
              label="Firmenlogo (max 500kb)"
          />
          <image-input
              :size-limit="500"
              v-model="company.info.storeWindowImage1"
              label="Schaufenster Bild1 (max 500kb)"
          />
          <image-input
              :size-limit="500"
              v-model="company.info.storeWindowImage2"
              label="Schaufenster Bild2 (max 500kb)"
          />
          <image-input
              :size-limit="500"
              v-model="company.info.storeWindowImage3"
              label="Schaufenster Bild3 (max 500kb)"
          />
          <image-input
              :size-limit="500"
              v-model="company.info.storeWindowImage4"
              label="Schaufenster Bild4 (max 500kb)"
          />
          <v-btn
              :disabled="isUploading"
              color="primary"
              @click="saveCompany"
          >
            Profil speichern
          </v-btn>
        </div>
        <div class="categories s3m-card">
          <h3>Unsere Kompetenzen:</h3>
          <div v-for="(value, key) in this.parentCategories" :key="key">
            <h4>{{ value.title }}</h4>
            <div v-for="option in value.categories" :key="option.id">
              <label v-if="!option.restricted || editUser.kundeObj.gruppen.some(group => group.id === '65c31d33e544150026f4aa9e')">
                <input type="checkbox" v-model="option.tempIsMember">
                {{ option.Bezeichnung }}
              </label>
            </div>
          </div>
          <div>
          <v-btn class="save-button" color="primary" @click="saveChanges">Kompetenzen speichern</v-btn>

          </div>
        </div>
      </v-card>
      <confirm-dialog ref="confirmDelete"/>
      <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    </div>
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import gruppemitgliedService from "@/services/gruppemitglied.service";
import modelService from "@/services/model.service";
import gruppeService from "@/services/gruppe.service";
import ImageInput from "@/components/ImageInput.vue";
import TextEditor from "@/components/TextEditor.vue";
import Snackbar from "@/components/Snackbar.vue";

export default {
  name: "BSuche",
  components: {Snackbar, TextEditor, ImageInput, ConfirmDialog },
  data() {
    return {
      editUser: null,
      azGroups: [],
      parentCategories: [],
      file: null,
      imageUrl: null,
      showLoader: false,
      company: null,
      isUploading: false,
      parentCats: {
        "e": "Energetische Sanierung",
        "i": "Inspiration",
        "g": "Gesunde Räume",
        "w": "Wärmedämmung",
        "n": "Neubau & Sanierung"
      },
      snackbarSuccess: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarError: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-close',
        color: 'error'
      },
    }
  },
  methods: {
    ...mapMutations(['updateManagers']),
    async fetchData() {
      console.log("fetch data in bsuche");
      console.log(this.editUser);
      this.parentCategories = {};
      this.azGroups = [];
      // 65c31d34e544150026f4ad4b
      this.isSAFMember = this.editUser.kundeObj.gruppen.some(gruppe => gruppe.id === '669ca043422de3002820585e');

      //this.azGroups = await sammelgruppeService.getModel('66280df0f15f3e0027ea3541');
      let [sg, userGroups, selfservGroups, company] = await Promise.all([
        APIService.getAllSammelGruppen([{name: 'name', value: 'Expertensuche Kategorien'}]),
        gruppemitgliedService.getGruppenForKunde(this.editUser.kundeObj.id, this.editUser.kundeObj.KundeTyp),
        APIService.getAllSelfServiceGroups(),
        APIService.getCompanyByUserId(this.editUser.kundeObj.id)
      ]);
      if(!company){
        this.company = this.createEmptyCompany();
      }else{
        this.company = company;
      }
      console.log(sg);
      console.log(userGroups);
      console.log(selfservGroups);
      console.log(company);

      if(sg.length > 0) {
        for (let i = 0; i < sg[0].groups.length; i++) {
          let gtmp;

          if (typeof sg[0].groups[i] === 'object') {
            gtmp = sg[0].groups[i];
          } else if (typeof sg[0].groups[i] === 'string') {
            gtmp = await gruppeService.getModel(sg[0].groups[i]);
          }else{
            return;
          }

          if(!gtmp.id){
            continue;
          }
          //Filter Maler 65c31d34e544150026f4afaf
          //Filter Trockenbaumonteur 65c31d34e544150026f4afad
          if(!this.isSAFMember && (gtmp.id === "65c31d34e544150026f4afaf" || gtmp.id === "65c31d34e544150026f4afad")){
            continue;
          }
          if (gtmp) {
            gtmp.isMember = userGroups.some(group => {
              if(group.id === gtmp.id) {
                // Zur Zuweisung von gruppemitgliedId, wenn group.id mit gtmp.id übereinstimmt
                gtmp.gruppemitgliedId = group.Gruppemitglied.id;
                return true;
              }
              return false;
            });
            gtmp.tempIsMember = gtmp.isMember;
            this.azGroups.push(gtmp);
          }
        }
      }
      this.azGroups = this.azGroups.map(group => {
        const parent = group.Kurzbezeichnung.substr(3,1); // Extrahiert den Buchstaben an der 4. Position (Index 3) des Strings
        return {...group, parent}; // Gibt ein neues Objekt zurück, das alle Eigenschaften von 'group' und zusätzlich den 'parent' Parameter hat
      });
      console.log(this.azGroups);
      let results = [];
      Object.keys(this.parentCats).forEach(key => {
        // Filtere azGroups basierend auf parentCats key
        let filteredGroups = this.azGroups.filter(group => group.parent === key);

        if (filteredGroups.length > 0) {
          results[key] = {
            title: this.parentCats[key],
            categories: filteredGroups
          };
        }
      });
      console.log(results);
      this.parentCategories = {...this.parentCategories, ...results};
      console.log(this.parentCategories);
    },
    async saveCompany () {
      console.log(this.company);
      await APIService.saveCompany(this.company)
      this.$refs.snackbar.show(this.snackbarSuccess)
      this.$emit('updated')
      await this.fetchData();
    },
    async updateGroupMemberStatus(group) {
      // Hier können Sie Ihren API-Aufruf platzieren, um den Status zu speichern.
      // Dies ist nur ein Dummy-Beispiel. Sie sollten es durch Ihren tatsächlichen API-Aufruf ersetzen.
      try {
        console.log(group);
        if(group.isMember){
          this.addToGroup(group);
        }else{
          this.removeFromGroup(group);
        }
      } catch (error) {
        console.error("Fehler beim Aktualisieren des Gruppenmitgliedsstatus: ", error);
        // Hier können Sie Fehlerbehandlung hinzufügen - z.B. das Anzeigen einer Fehlermeldung für den Benutzer
      }
    },
    async saveChanges() {
      this.showLoader = true;
      // Iteriert über alle Gruppen und überprüft, ob sich der Zustand der CheckBox geändert hat
      // Wenn sich der Zustand geändert hat, dann wird die API-Aktion ausgeführt und der isMember Wert aktualisiert.
      for (const group of this.azGroups) {
        if (group.isMember !== group.tempIsMember) {
          if (group.tempIsMember) {
            await this.addToGroup(group);
          } else {
            await this.removeFromGroup(group);
          }
        }
      }
      console.log("saved!");
      this.showLoader = false;
      this.$refs.snackbar.show(this.snackbarSuccess)
    },
    async addToGroup(item){
      let model = this.createModel(item);
      model.InternetUser = this.editUser.kundeObj.InternetUser;
      console.log(model);
      await gruppemitgliedService.saveModel(model, false, false);
      //await APIService.addMeToGroup(model.Gruppe);
    },
    async removeFromGroup(item){
      console.log("remove from group");

      item.InternetUser = this.editUser.kundeObj.InternetUser;
      item.Gruppe = item.id;
      item.id = item.gruppemitgliedId;

      console.log(item);
      await gruppemitgliedService.deleteModel(item, false, false );
      await modelService.getContext(item);
    },
    createModel(gruppe){
      const current = new Date();
      let month = current.getMonth()+1;
      month = (month < 10) ? '0'+month : month;
      let day = current.getDate();
      day = day < 10 ? '0'+day : day;
      const date = `${current.getFullYear()}-${month}-${day}`;
      let model = {};
      if(this.editUser.kundeObj.KundeTyp === "F"){
        model = {
          Gruppe: gruppe.id,
          GruppeFunktion: "643562aa7304db0027195b0a",
          F_Kunde: this.editUser.kundeObj.id,
          Datum: date
        };
      }else{
        model = {
          Gruppe: gruppe.id,
          GruppeFunktion: "643562aa7304db0027195b0a",
          P_Kunde: this.editUser.kundeObj.id,
          Datum: date
        };
      }

      return model;
    },
    createEmptyCompany(){
      return {
        contact: {
          facebook: "",
          instagram: "",
          clickToMeet: "",
        },
        info: {
          description: "",
          openingHours: "",
          storeWindowImage1: {},
          storeWindowImage2: {},
          storeWindowImage3: {},
          storeWindowImage4: {},
        },
        logo: {},
        kunde: this.editUser.kundeObj.id,
      }
    }
  },
  mounted() {
    console.log("mounten - bsuche");
   this.fetchData();
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
  },
  async beforeRouteEnter(to, from, next) {
    console.log("beforerouteenter bsuche");
    next( async vm => {
      // Wir überprüfen, ob 'editUser' im Zustand gesetzt ist und benutzen diesen, ansonsten benutzen wir 'user'
      // 'editUser' wird zu 'user' wenn 'editUser' gesetzt ist
      vm.editUser = vm.$store.state.editUser ?? vm.$store.state.user
    })
  }
}
</script>

<style scoped>

</style>
