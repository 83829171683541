<template>
  <div class="s3m-statistics_list">
    <h1>Statistiken</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            Hier kommen die Statistiken hin!
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "Statistics",
  props: ['userId']

}
</script>

<style scoped>

</style>
