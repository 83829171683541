var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.listClass},[_c('h1',[_vm._v(_vm._s(_vm.title))]),_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Suche (min. 3 Zeichen)","single-line":"","hide-details":"","value":_vm.search},on:{"input":_vm.searchHandler}})],1),_c('v-spacer')],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"search":_vm.search,"items-per-page":50,"hide-default-footer":_vm.users.length < 5,"options":_vm.paginationSync,"footer-props":{
          'items-per-page-text': 'Datensätze',
          'items-per-page-options': [15, 50, 100, -1]
        }},on:{"update:options":function($event){_vm.paginationSync=$event}},scopedSlots:_vm._u([{key:"item.InternetUser",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"s3m_pointer",on:{"click":function($event){return _vm.openLoginUser(item)}}},[_vm._v(_vm._s(item.InternetUser))])]}},{key:"item.completeName",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"s3m_pointer",on:{"click":function($event){return _vm.openKunde(item, 'P')}}},[_vm._v(_vm._s((item.completeName) ? item.completeName : ""))])]}},{key:"item.F_KundeObj.KurzbezeichnungExtern",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"s3m_pointer",on:{"click":function($event){return _vm.openKunde(item, 'F')}}},[_vm._v(_vm._s((item.F_KundeObj && item.F_KundeObj.KurzbezeichnungExtern) ? item.F_KundeObj.KurzbezeichnungExtern : ""))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")])]}}])})],1),_c('user-edit-dialog',{ref:"userEditDialog"}),_c('generated-form',{ref:"generatedForm"}),_c('confirm-dialog',{ref:"confirmDelete"}),(_vm.showLoader)?_c('Spinner',{staticClass:"s3m-spinner",attrs:{"name":"circle","color":"#008bc4"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }