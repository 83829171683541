<template>
  <div class="s3m-settings_list">
    <h1>Kundendaten</h1>
    <v-card class="s3m-card-content">
      <div class="comp_mail">Hier können Sie Ihre registrierten Kundendaten ansehen und ändern</div>
      <GeneratedForm ref="generatedForm"></GeneratedForm>
      <div class="comp-logo" v-if="false">
        <br>
        <h3>Firmenlogo</h3>
        Falls Sie einen unserer Services nutzen, bei dem Ihr Unternehmenslogo erscheinen soll, können Sie hier Ihr Logo hochladen.
        <br><br>
        <v-file-input
            :size-limit="500"
            v-model="file"
            label="Logo (max 500kb)"
            prepend-icon="mdi-camera"
            accept="image/jpeg"
            @change="previewImage"
        ></v-file-input>
        <v-btn class="save-button" color="primary" @click="saveLogo">Speichern</v-btn>
        <img class="s3m-preview-image" v-if="imageUrl" :src="imageUrl" alt="Logo Vorschau" />
      </div>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
    <snackbar ref="snackbar"></snackbar>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import store from "@/store";
import GeneratedForm from "@/components/GeneratedForm.vue";
import Snackbar from "@/components/Snackbar.vue";

export default {
  name: "Settings",
  components: {Snackbar, ConfirmDialog, GeneratedForm },
  data() {
    return {
      teamMembers: [],
      managers: {},
      file: null,
      imageUrl: null,
      imageFilename: "",
      editUser: null,
      showLoader: false,
      snackbarSuccess: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarSuccessLogo: {
        text: 'Logo erfolgreich gespeichert',
        icon: 'mdi-check-circle',
        color: 'success'
      },
      snackbarError: {
        text: 'Daten erfolgreich gespeichert',
        icon: 'mdi-close',
        color: 'error'
      },
    }
  },
  methods: {
    ...mapMutations(['updateManagers']),
    saveSettings () {
      APIService.updateUser({id: this.editUser.id, managers: this.managers})
      store.commit('updateManagers', this.managers)
    },
    getUserItemText(item) {
      return `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''} (${item.email})`;
    },
    removeManager(item) {
      const index = this.managers.indexOf(item.id)
      if (index >= 0) this.managers.splice(index, 1)
    },
    async previewImage() {
      console.log(this.file);
      if (this.file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          console.log("read file");
          this.imageUrl = e.target.result;
        };
        reader.readAsDataURL(this.file);
      }
    },
    async saveLogo(){
      this.showLoader = true;
      await APIService.logoUpload(this.file, false, false, "", this.imageFilename);
      await APIService.setLogoName(this.imageFilename, this.editUser.kundeObj.id)
      this.showLoader = false;
      this.$refs.snackbar.show(this.snackbarSuccessLogo)
    },
    openForm() {
      console.log(this.editUser);
      this.$refs.generatedForm.show('kunde', this.editUser.kundeObj, this.editUser.kundeObj.id, "R", true, false, false);  // Ersetzen Sie 'type' und 'model' durch Ihre tatsächlichen Argumente
    },
  },
  mounted() {
    //("kunde", item, item.id, this.kundetyp)
    this.openForm();
    this.editUser = this.user;
    if(this.$store.state.editUser){
      this.editUser = this.$store.state.editUser;
    }
    console.log(this.editUser);
    if(this.editUser && this.editUser.kundeObj) {
      console.log("logoName: "+this.editUser.kundeObj.logoName);
      if (this.editUser.kundeObj.logoName?.trim()) {
        this.imageFilename = this.editUser.kundeObj.logoName;
        this.imageUrl = "https://api.backend.digital.stuck-komzet.de:444/public/logos/" + this.imageFilename;
      } else if (this.editUser.kundeObj.IdKunde) {
        let imageNbr = parseInt(this.editUser.kundeObj.IdKunde, 10).toString();
        this.imageFilename = imageNbr + "_Logo-Betriebssuche.jpg";
        this.imageUrl = "https://api.backend.digital.stuck-komzet.de:444/public/logos/" + this.imageFilename;
      }
    }
  },
  computed: {
    ...mapState(['user']),
  },
  async beforeRouteEnter(to, from, next) {
    next( async vm => {
      // Wir überprüfen, ob 'editUser' im Zustand gesetzt ist und benutzen diesen, ansonsten benutzen wir 'user'
      const targetUser = vm.$store.state.editUser ?? vm.$store.state.user

      // 'editUser' wird zu 'user' wenn 'editUser' gesetzt ist
      vm.editUser = targetUser

      vm.managers = [...targetUser.managers]
      vm.teamMembers = await APIService.getTeamMembers(targetUser.id)
    })
  }
}
</script>

<style scoped>

</style>
