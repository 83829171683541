<template>
  <div class="s3m-internetuser_list">
    <h1>User Logins</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                :value="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
                @input="searchHandler"
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                class="mr-2"
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              Kunde
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="filteredUsers"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
          @current-items="getFiltered"
          ref="userTable"
          :footer-props="{
            'items-per-page-text':'Zeilen',
            'items-per-page-options': [15, 50, 100, -1]
          }"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
          <!--
          <v-icon
              small
              @click="showLicenses(item)"
          >
            mdi-certificate
          </v-icon>
          <v-icon
              small
              @click="showPassed(item)"
          >
            mdi-check-bold
          </v-icon>
          -->
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <user-edit-dialog ref="userEditDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapGetters, mapState} from "vuex";
import Fuse from "fuse.js";

export default {
  name: 'UserListOrig',
  mixins: [vuetifyTableMixin],
  components: {
    UserEditDialog,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      filteredUsers: [],
      timeout: null
    }
  },
  methods: {
    searchHandler (text) {
      if (this.timeout)
        clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        // Stellen Sie sicher, dass die keys den Namen Ihrer Felder in den Benutzerdaten entsprechen
        let options = {
          shouldSort: true,
          threshold: 0.3,
          location: 0,
          includeScore: true,
          distance: 100,
          maxPatternLength: 32,
          minMatchCharLength: 2,
          useExtendedSearch: true, // Enable extended search
          tokenize: true,
          matchAllTokens: true,
          keys: ["suchstring"]
          //keys: ["Vorname", "Nachname1", "Nachname2", "PLZ_Str", "Strasse", "InternetUser", "Ort"]
          //keys: ["Vorname", "Nachname1", "Ort"]
        };

        text = text.trimEnd();

        if(text.length > 2) {
          let fuse = new Fuse(this.users, options);
          let extendedSearchQuery = text.split(' ').map(word => `'${word}`).join(' '); // Format the search query as extended search
          let results = fuse.search(extendedSearchQuery);
          console.log(this.users);
          console.log(results);
          this.filteredUsers = results.map(result => result.item);
          console.log(this.filteredUsers);
        }else if(text.length == 0){
          this.filteredUsers = this.users;
        }
      }, 500); // delay
    },
    getFiltered(){
      //this.filteredUsers = this.$refs.userTable.$children[0].filteredItems;
    },
    createUser () {
      this.$refs.userEditDialog.show()
    },
    editUser (item) {
      this.$refs.userEditDialog.show(item)
    },
    showPassed (item){
      this.$refs.userPassedDialog.show(item);
    },
    showLicenses (item){
      this.$refs.userLicenseDialog.show(item);
    },
    importUsers() {
      this.$refs.userImportDialog.show(this.user.id)
    },
    migrateUsers() {
      this.$refs.userMigrationDialog.show()
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: "Entfernen",
        text: "Soll der Kunde "+user.firstName + " " + user.lastName + " entfernt werden?",
        confirm: "Entfernen"
      }).then(() => {
        APIService.deleteUser(user).then(() => {
          store.dispatch('fetchUsers')
          console.log('Item deleted')
        })
      })
    }
  },
  computed: {
    ...mapState(['user','users']),
    ...mapGetters(['hasRole']),
    headers(){ return [
      { text: "Vorname", value: 'firstName' },
      { text: "Nachname", value: 'lastName' },
      { text: 'E-Mail Adresse für Login', value: 'email' },//f.zankl
      {
        text: "Bearbeiten",
        value: 'actions',
        sortable: false,
        align: 'end'
      },
      {
        value: 'completeName',
        align: ' d-none'
      }
    ]}
  },
  mounted() {

    for(let i=0; i < this.users.length; i++){
      this.users[i].completeName = this.users[i].firstName + " " + this.users[i].lastName;
      this.users[i].suchstring = this.users[i].firstName + " " + this.users[i].lastName + " " + this.users[i].email;
    }
    this.filteredUsers = this.users;
    console.log("mounted filtered users");
    console.log(this.filteredUsers);
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('fetchUsers').then(() => next())
  }
}
</script>

<style scoped>

</style>

