<template>
  <div class="s3m-sg_group_list">
    <h1>{{title}}</h1>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Suche"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="error"
                dark
                @click="syncAllGroups"
            >
              <v-icon left>mdi-sync-alert</v-icon>
              Alle Gruppen synchronisieren
            </v-btn>
          </v-col>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createGroup"
            >
              <v-icon left>mdi-plus</v-icon>
              Berechtigungsgruppe
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="items.length < 5"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editGroup(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              class="mr-2"
              @click="showMemberGroups(item)"
          >
            mdi-account-group
          </v-icon>
          <v-icon
              small
              @click="syncGroup(item)"
          >
            mdi-sync
          </v-icon>
          <v-icon
              small
              @click="deleteGroup(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
    <generated-form ref="generatedForm"/>
    <s-g-gruppe-select-dialog ref="SGGruppeSelectDialog" />
    <Spinner v-if="showLoader" class="s3m-spinner" name="circle" color="#008bc4" />
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import APIService from "@/services/APIService";
import GeneratedForm from "@/components/GeneratedForm.vue";
//import APIServiceWP from "@/services/APIServiceWP";
import SGGruppeSelectDialog from "@/components/SGGruppeSelectDialog.vue";
import modelService from "@/services/model.service";
import sammelgruppeService from "@/services/sammelgruppe.service";
export default {
  name: "SGroupList",
  mixins: [vuetifyTableMixin],
  components: {
    SGGruppeSelectDialog,
    GeneratedForm,
    ConfirmDialog
  },
  data() {
    return {
      sortBy: 'name',
      sortDesc: false,
      search: '',
      title: 'Berechtigungsgruppen',
      items: [],
      showLoader: false,
      headers: [
        { text: 'Bezeichnung', value: 'name' },
        { text: 'Beschreibung', value: 'description' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
      ]
    }
  },
  methods: {
    async entered(){
      if(this.$route.query.filter && this.$route.query.value){
        this.fetchGroups(this.$route.query.filter, this.$route.query.value);
      }else{
        this.fetchGroups(this.$route.query.filter, this.$route.query.value)
      }
    },
    createGroup () {
      this.$refs.generatedForm.show("sammelgruppe", null)
    },
    async syncAllGroups(){
      this.$refs.confirmDelete.show({
        title: 'Alle Gruppen synchronisieren',
        text: `Wollen Sie alle Berechtigunsgruppen mit allen Seiten synchronisieren? Das kann SEHR LANGE dauern.`,
        confirm: 'JA, dennoch synchronisieren'
      }).then(async () => {
        this.showLoader = true;
        for (const item of this.items) {
          await sammelgruppeService.syncModel(item, item.id, true);
        }
        await this.fetchGroups();
        this.showLoader = false;
      })
    },
    async syncGroup(item){
      this.$refs.confirmDelete.show({
        title: 'Gruppe synchronisieren',
        text: `Wollen Sie <b>${item.name}</b> mit allen Seiten synchronisieren? Das kann länger dauern.`,
        confirm: 'Ja, synchronisieren'
      }).then(async () => {
        this.showLoader = true;
        await sammelgruppeService.syncModel(item, item.id,true);
        await this.fetchGroups();
        this.showLoader = false;
      })

    },
    editGroup (item) {
      this.$refs.generatedForm.show("sammelgruppe", item, item.id, "", false)
      //this.$refs.userEditDialog.show(item)
    },
    async fetchGroups(filter = "", value = ""){
      console.log(filter+value);
      //this.users = await APIService.getAllGruppe(filter, value);
      this.items = await APIService.getAllSammelGruppen();
    },
    async deleteGroup(item) {
      this.$refs.confirmDelete.show({
        title: 'Gruppe entfernen',
        text: `Wollen sie <b>${item.name}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(async () => {
        await modelService.deleteModel(item, "sammelgruppe");
        console.log("fetch groups");
        await this.fetchGroups();
      })
    },
    showMemberGroups(item){
      this.$refs.SGGruppeSelectDialog.show(item);
    }
  },
  mounted() {
    this.$root.$off("updatesammelgruppeList");
    this.$root.$on('updatesammelgruppeList', (msg) => {
      console.log("update list "+msg);
      if(msg == 'sammelgruppe'){

        this.fetchGroups();
      }
    });
  },
  /*
  computed: {
    ...mapState(['users'])
  },*/
  watch:{
    $route (){
      this.entered();
    }
  },
  created() {
    this.entered();
  }
}
</script>

<style scoped>

</style>
